<template>
  <div>
    <div>
      <Header 
        @download-clicked="setFileBrowserValues"
      />
      <div class="progress">
        <div class="progress_bar" :style="progressStatus" />
      </div>
    </div>
    <div class="file_action_contents">
      <div class="file_action_wrapper">
        <main-action />
        <files
          @download-clicked="setFileBrowserValues"
        />
      </div>
      <prompts></prompts>
    </div>
  </div>
</template>

<script>
import __C from '@/primitives/_constant_'
import { mapState, mapMutations } from 'vuex'
import Header from '@/containers/layout/JFtb'
import Files from '../../lib/filebrowser/src/views/Files'
// import Search from '../../lib/filebrowser/src/components/Search'
import MainAction from '../../lib/filebrowser/src/components/Sidebar'
import Prompts from '../../lib/filebrowser/src/components/prompts/Prompts'

export default {
  name: 'j-comm-file-manager',
  components: {
    Header,
    Files,
    MainAction,
    Prompts,
    // Search
  },
  computed: {
    ...mapState(['fbname', 'progress', 'show']),

    progressStatus() { return `width: ${this.progress}%;` },
    routePath() { return this.$route.path }
  },
  watch: {
    routePath(val) {
      if(val == '/service/project/share_folder/public_data') var fbname_ = __C.FILE_BROWSER.ROOT_PUBLIC_DATA
      else if(val == '/service/project/share_folder/jinsolution_user_manual') fbname_ = __C.FILE_BROWSER.ROOT_MANUAL_DATA
      else fbname_ = __C.FILE_BROWSER.ROOT_JGS_DATA
      this.setFbSharedName(fbname_)
    }
  },
  created() {
    if(this.$route.path == '/service/project/share_folder/public_data') var fbname_ = __C.FILE_BROWSER.ROOT_PUBLIC_DATA
    else if(this.$route.path == '/service/project/share_folder/jinsolution_user_manual') fbname_ = __C.FILE_BROWSER.ROOT_MANUAL_DATA
    else fbname_ = __C.FILE_BROWSER.ROOT_JGS_DATA
    this.setFbSharedName(fbname_)
  },
  methods: {
    ...mapMutations([ 'initFilebrowser', 'setFbSharedName']),

    setFileBrowserValues(v) {
      this.$emit('download-clicked', v)
    }
  }
}
</script>
<style lang="stylus" scoped>
#download {
  max-width: 15em;
  border: solid 1px #ff00ff;
}

.progress {
  width: 100%;
  height: 3px;
  background-color: #f5f5f5;

  .progress_bar {
    width: 0%;
    height: 3px;
    background-color: #03a9f4;
  }
}

.file_action_contents {
  background-color: #fff;
  padding-right: 1.2rem;
  padding-bottom: 1.2rem;
  min-height: calc(100vh - 25rem)

  .file_action_wrapper {
    display: flex;
    max-width: 150rem;

    div:first-of-type {
      width: 100%;
    }
  }
}
</style>